@keyframes preloader-loading {
  from {
    transform: scaleY(0.7);
  }

  to {
    transform: scaleY(1);
  }
}

@keyframes preloader-loading-2 {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0.7);
  }
}

@keyframes header-fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes register-subrow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}