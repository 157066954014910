.bar-inquiry {
  border-bottom: 3px solid var(--textColorLighter);
  clear: fix;
  padding: calc(var(--space) * 0.5) var(--space);

  &__btn {
    float: right;
  }

  input[type='submit'] {
    background: var(--highColor);

    &:--link {
      background: var(--textColor);
    }
  }
}