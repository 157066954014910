select,
input,
textarea {
  @extend animate;
  border: 1px solid color(var(--textColor) l(85%));
  color: var(--textColor);
  font-family: var(--fontFamily);
  font-size: calc(var(--fontSize) * 0.9);
  padding: calc(var(--space) * 0.5);

  &:--link {
    border-color: var(--textColorMiddle);
  }
}

select {
  color: var(--textColor);
  font-size: var(--fontSize);
}

input[type='button'],
input[type='reset'],
input[type='submit'],
button,
.button {
  @extend animate;
  background: var(--textColor);
  border-radius: var(--radius);
  border: none;
  color: var(--bgColor);
  cursor: pointer;
  display: inline-block;
  font-size: var(--fontSize);
  margin: calc(var(--space) * 0.5) 0;
  padding: calc(var(--space) * 0.5) var(--space);
  text-align: center;

  &:--link {
    background: var(--brandColorLighter);
    color: var(--bgColor);

    .button__badge {
      color: var(--brandColorLighter);
    }
  }

  .button__badge {
    @extend animate;
    background: var(--bgColor);
    border-radius: calc(var(--radius) * 2);
    color: var(--textColor);
    display: inline-block;
    font-size: calc(var(--fontSize) * 0.8);
    font-weight: bold;
    margin-left: calc(var(--space) * 0.5);
    min-width: calc(var(--space) * 2);
    padding: 0 calc(var(--space) * 0.5);
    text-align: center;
  }

  &.button {
    &--high {
      background: var(--highColor);

      &:--link {
        background: var(--textColor);
      }
    }

    &--facebook {
      background: #3b5998;
      width: 100%;

      @media (--screen-480) {
        width: auto;
      }

      &:--link {
        background: var(--textColor);
      }
    }

    &--google {
      background: #db4437;
      margin-left: calc(var(--space) * 0);
      width: 100%;

      @media (--screen-480) {
        margin-left: calc(var(--space) * 0.5);
        width: auto;
      }

      &:--link {
        background: var(--textColor);
      }
    }

    &--more {
      @extend animate;
      background: var(--brandColorLighter);
      border-radius: var(--radius) 0 0 0;
      bottom: 0;
      color: var(--bgColor);
      display: block;
      font-size: calc(var(--fontSize) * 0.9);
      font-weight: bold;
      margin: 0;
      padding: calc(var(--space) * 0.5) var(--space);
      position: absolute;
      right: 0;

      &:--link {
        background: var(--textColor);
      }
    }

    &--prev:before {
      @mixin fontAwesomeIcon inherit, f104, 0 calc(var(--space) * 0.5) 0 0;
    }

    &--next:after {
      @mixin fontAwesomeIcon inherit, f105, 0 0 0 calc(var(--space) * 0.5);
    }
  }

  + button,
  + .button {
    margin-left: calc(var(--space) * 0.5);
  }
}

input#goog-wm-qt {
  @media (--screen-640) {
    width: auto;
  }
}

label {
  .&--required {
    display: inline-block;
    text-indent: calc(var(--space) * -0.75);
    padding-left: calc(var(--space) * 1.5);

    &:before {
      font-size: calc(var(--fontSize) * 0.7);
      font-weight: normal;

      @mixin fontAwesomeIcon
        var(--highColor), f069, 0 var(--space) 0 0;
    }
  }
}

input[type='checkbox'],
input[type='radio'] {
  display: none;

  + label {
    display: inline-block;
    margin-left: calc(var(--space) * 1.5);
    text-indent: calc(var(--space) * -1.5);

    &:before {
      @mixin fontAwesomeIcon
        inherit, f096, 0;
      text-indent: 0;
      width: calc(var(--fontSize) * 1.5);
    }
  }

  &:checked + label {
    color: var(--brandColor);
  }

  label + & + label {
    padding-left: calc(var(--space) * 1.5);
  }
}

input[type='checkbox'] {

  + label:before {
    content: '\f096';
  }

  &:checked + label:before {
    content: '\f046';
  }
}

input[type='radio'] {

  + label:before {
    content: '\f10c';
  }


  &:checked + label {

    &:before {
      content: '\f058';
    }
  }
}

input[type='file'] {

  &.inputfile + label {
    display: none;
  }

  &.js-inputfile--hidden {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;

    + label {
      clear: fix;
      display: flex;

      @media (--screen-768) {
        display: block;
      }

      .filepath {
        @mixin textOverflow calc(calc(var(--space) * 2.5) + 2px), 1;
        background: var(--textColorLighter);
        border: 1px solid var(--textColorMiddle);
        border-radius: var(--radius) 0 0 var(--radius);
        border-width: 1px 0 1px 1px;
        cursor: pointer;
        height: calc(calc(var(--space) * 2.5) + 2px);
        lost-column: 8/12 0 0;
        padding: calc(var(--space) * 0.5) var(--space);
        white-space: nowrap;

        @media (--screen-1300) {
          lost-column: 9/12 0 0;
        }
      }

      .button {
        border: 1px solid var(--textColor);
        border-radius: 0 var(--radius) var(--radius) 0;
        height: calc(calc(var(--space) * 2.5) + 2px);
        lost-column: 4/12 0 0;
        text-align: center;
        margin: 0;
        min-width: calc(var(--space) * 10);

        &:--link {
          border-color: var(--brandColorLighter);
        }

        @media (--screen-1300) {
          lost-column: 3/12 0 0;
        }

        i {
          margin-right: calc(var(--space) * 0.5);
        }
      }
    }
  }
}

#check_code {
  width: calc(var(--space) * 10);
}

form {
  input[type='password'],
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  select,
  textarea {
    width: 100%;
  }

  textarea {
    overflow-x: hidden;
    overflow-y: auto;
    word-wrap: break-word;
  }

  .&__row {
    @extend reset;
    list-style: none;
  }

  .&__list {
    clear: fix;

    &--last {
      margin-top: calc(var(--space) * 1.5);
      text-align: center;

      @media (--print) {
        display: none;
      }

      .button {
        font-size: 1em;
        padding: calc(var(--space) * 0.5) calc(var(--space) * 1.5);
      }

      a {
        color: color(var(--textColor) l(60%));
        font-size: calc(var(--fontSize) * 0.9);
        padding: 0 var(--space);

        &.button {
          color: var(--bgColor);
        }

        + a {
          border-left: 3px solid var(--textColorMiddle);
        }
      }
    }

    + li {
      margin-top: var(--space);

      @media (--print) {
        margin-top: calc(var(--space) * 0.2);
      }
    }

    > label {
      font-weight: bold;
      line-height: calc(var(--space) * 2.25);

      @media (--screen-768) {
        lost-column: 3/12;
      }

      @media (--print) {
        display: inline-block;

        &:after {
          content: ':';
          display: inline-block;
          padding-right: calc(var(--space) * 0.2);
        }
      }
    }
  }

  .&__content {
    clear: fix;
    line-height: calc(var(--space) * 2.25);

    @media (--screen-768) {
      lost-column: 9/12;
    }

    @media (--print) {
      display: inline-block;
    }

    &--column2 > * {

      @media (--screen-640) {
        lost-waffle: 1/2;
      }

      &:nth-child(1n) {
        @media (--screen-640) {
          margin-bottom: calc(var(--space) * 0.5);
        }
      }
    }

    &--column3 > * {

      @media (--screen-640) {
        lost-waffle: 1/3;
      }

      &:nth-child(1n) {
        @media (--screen-640) {
          margin-bottom: calc(var(--space) * 0.5);
        }
      }
    }

    span {
      display: block;
      color: color(var(--textColor) l(60%));
      font-size: calc(var(--fontSize) * 0.9);
      padding: calc(var(--space) * 0.5) 0 calc(var(--space) * 0.5) calc(var(--space) * 0.5);
    }
  }

  .&__email {
    color: var(--brandColorLighter);
  }

  .&__name {

    select {
      @media (--screen-640) {
        lost-column: 2/12;
      }
    }

    input {
      margin-top: calc(var(--space) * 0.5);

      @media (--screen-640) {
        margin-top: 0;
      }
    }

    input[type='text'] {
      @media (--screen-640) {
        lost-column: 5/12;
      }
    }

    input#SR_Name {
      @media (--screen-640) {
        lost-column: 10/12;
      }
    }
  }

  .&__tel {
    .form__tel-country,
    .form__tel-area {
      lost-column: 3/18 0 0;

      @media (--screen-640) {
        lost-column: 4/18;
        margin-right: 0;
      }

      &:nth-child(1n) {
        margin-right: calc(var(--space) * 0.5);
      }
    }

    span {
      lost-column: 1/18 0 0;

      @media (--screen-640) {
        lost-column: 1/18 0 0;
      }
    }

    .form__tel-phone {
      lost-column: 10.6/18;

      @media (--screen-640) {
        lost-column: 9/18;
      }
    }
  }

  .&__category {
    &__row,
    &__sub-row {
      @extend reset;
      list-style: none;
    }

    &__list {
      clear: fix;

      + li {
        margin-top: calc(var(--space) * 0.5);
      }
    }

    &__item {
      @media (--screen-640) {
        lost-column: 1/2;
      }

      > input:checked ~ ul {
        animation-name: register-subrow;
        animation-duration: 1s;
        display: block;
      }
    }

    &__sub-row {
      @extend animate;
      background: var(--textColorLighter);
      display: none;
      margin: calc(var(--space) * 0.5) 0 var(--space) calc(var(--space) * -0.7);
      padding: calc(var(--space) * 0.7);
    }
  }

.&__card {
    .form__card-number {
      lost-column: 3/18 0 0;
      min-width: 3.3em;

      @media (--screen-640) {
        lost-column: 3.7/18 0 0;
        margin-right: 0;
      }

      &:nth-child(1n) {
        margin-right: calc(var(--space) * 0.5);
      }
    }

    span {
      lost-column: 0.8/18 0 0;


      @media (--screen-640) {
        lost-column: 1/18 0 0;
      }
    }
  }

  .&__url {
    span,
    input[type='text'] {
      width: auto; 
    }
  }
}