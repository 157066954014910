.inquiry {

  &__row {
    @extend reset;
    list-style: none;
    margin-top: var(--space);
  }

  &__list {
    clear: fix;
    padding: calc(var(--space) * 0.5) 0;

    + li {
      border-top: 1px dotted var(--textColorMiddle);
    }

    &-supplier {
      @media (--screen-1024) {
        lost-column: 5/12;
      }
    }

    &-product {
      margin: calc(var(--space) * 0.5) 0 calc(var(--space) * 1);

      @media (--screen-1024) {
        lost-column: 7/12;
        margin: 0;
      }

      p {
        @extend reset;
        text-indent: calc(var(--space) * -1.5);
        margin-left: calc(var(--space) * 1.5);

        + p {
          margin-top: calc(var(--space) * 0.5);
        }
      }

      input:checked + label {
        color: var(--textColor);
      }
    }
  }
}