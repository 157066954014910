* {
  line-height: calc(var(--space) * 1.5);
}

html,
body {
  font-family: var(--fontFamily);
}

a {
  @extend animate;
  color: var(--brandColor);
  text-decoration: none;

  &:--link {
    color: var(--brandColorLighter);
  }
}

ul,
ol {
  padding-left: calc(var(--space) * 1.5);

  @media (--screen-768) {
    padding-left: calc(var(--space) * 3);
  }

  li + li {
    margin-top: calc(var(--space) * 0.3);
  }

  .&--nolist {
    list-style: none;
  }
}

hr {
  border: 1px solid color(var(--textColor) a(10%));
  border-width: 0 0 3px 0;
  margin: calc(var(--space) * 1.5);
}

h1 {
  font-size: calc(var(--fontSize) * 1.5);

  @media (--screen-768) {
    font-size: calc(var(--fontSize) * 2);
  }
}

.text {
  &--brandColorLighter {
    color: var(--brandColorLighter);
  }

  &--high {
    color: var(--highColor);
  }

  &--grey {
    color: var(--textColorMiddle);
  }

  &--small {
    font-size: calc(var(--space) * 0.9);
  }

  &--big {
    font-size: calc(var(--space) * 1.2);
  }

  &--right {
    text-align: right;
  }

  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }
}