.supplier-box {
  @mixin productsList;
  @mixin productsListInput list;
  @mixin productsListThumb 4, 3;

  .list__text {
    clear: fix;

    @media (--screen-768) {
      lost-column: 13/14;
    }

    .list__title {
      margin: 0 0 var(--space);

      a {
        @extend animate;
        @mixin textOverflow calc(var(--space) * 3), 2;
        color: var(--brandColorLighter);
        font-size: calc(var(--fontSize) * 1.1);

        &:--link {
          color: var(--textColor);
        }
      }
    }

    .list__info {
      @mixin textOverflow calc(var(--space) * 4.5), 3;
      margin: 0 0 calc(var(--space) * 2);
    }

    h5 {
      font-size: var(--fontSize);
      margin: 0;
    }

    p {
      @mixin textOverflow calc(var(--space) * 4.5), 3;
      font-size: var(--fontSize);
      margin: 0;

      a {
        @extend animate;
        border-bottom: 1px solid transparent;
        color: var(--textColor);

        &:--link {
          border-color: var(--textColor);
        }

        + a {
          margin-left: calc(var(--space) * 0.5);
        }
      }
    }

    .list__link {
      background: var(--highColor);
      color: var(--bgColor);
      font-size: var(--fontSize);

      &:--link {
        background: var(--textColor);
      }
    }

    .more {
      border-bottom: 1px solid var(--brandColorLighter);
      color: var(--brandColorLighter);
      font-size: calc(var(--fontSize) * 0.9);
    }
  }

  .list__thumb-link + .list__text {

    @media (--screen-640) {
      lost-column: 9/14;
    }

    @media (--screen-1024) {
      lost-column: 10/14;
    }
  }
}