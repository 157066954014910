footer {
  background: var(--brandColor);
  color: var(--bgColor);
  font-size: calc(var(--fontSize) * 0.9);
  padding-bottom: calc(var(--space) * 3);

  @media (--screen-640) {
    padding-bottom: calc(var(--space) * 0);
  }

  @media (--print) {
    display: none;
  }

  a {
    @extend animate;
    color: var(--bgColor);

    &:--link {
      color: var(--highColor);
    }
  }

  p {
    color: color(var(--brandColorLighter) l(80%));
    font-size: var(--fontSize);
    font-weight: bold;
    text-transform: uppercase;
  }

  ul {
    @extend reset;
    list-style: none;
  }

  .menu-& {
    border-bottom: 1px solid color(var(--bgColor) a(30%));
    padding: calc(var(--space) * 2) var(--space) var(--fixSpace) var(--space);

    &__row {
      clear: fix;
    }

    &__list {

      @media (--screen-480) {
        lost-column: 2/4 2;
      }

      @media (--screen-768) {
        lost-column: 1/4 4;
      }

      + .menu-footer__list {
        border-top: 1px dotted color(var(--bgColor) a(30%));
        margin-top: var(--space);
        padding-bottom: var(--space);

        @media (--screen-480) {
          border-top: none;
          margin-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }

  .contact-info {
    margin: var(--space);
    max-width: 640px;
    text-align: center;

    @media (--screen-640) {
      margin: var(--space) auto;
    }

    li {
      display: inline-block;
      line-height: var(--fontSize);
      padding: 0 calc(var(--space) * 0.5);

      + li {
        border-left: 1px solid color(var(--bgColor) a(30%));
      }
    }
  }

  .copyright {
    padding: var(--space);
    text-align: center;

    @media (--screen-1300) {
      padding: var(--space) 0;
    }
  }
}