.supplier-product {

  /* supplier-product-series start */
  &-series {

    .list {
      &__input {
        lost-column: 2/14 0 0;
      }

      &__thumb-link {
        lost-column: 6/14;
      }

      &__text {
        clear: fix;
        lost-column: 12/14;
      }
    }
  }
  /* supplier-product-series end */

  /* supplier-product-categories start */
  &-categories {

    .supplier-product__line {

      @media (--screen-640) {
        padding: 0 var(--space);
      }
    }

    .list {

      &__thumb-link {
        lost-column: 6/12 2;
      }

      &__text {
        clear: fix;
      }
    }
  }
  /* supplier-product-categories end */

  /* supplier-product-detail start */
  &-detail {

    /* supplier-product-detail--float start */
    &--float {
      .supplier-product-detail {
        &__img {
          @media (--screen-640) {
            lost-column: 4/12 0 0;
          }
        }

        &__editor-note {
          @media (--screen-640) {
            lost-column: 8/12 0 0;
          }
        }
      }
    }
    /* supplier-product-detail--float end */

    /* supplier-product-detail--big start */
    &--big {
      .supplier-product-detail {
        &__img {
          margin: 0 auto calc(var(--space) * 2);
          width: 90%;

          &-list {
            text-align: center;
          }
        }
      }
    }
    /* supplier-product-detail--big end */

    &__img {
      @media (--screen-640) {
        padding-right: var(--fixSpaceSmaller);
      }

      a {
        display: block;
        text-align: center;
      }

      img {
        margin: auto;
        border: 1px solid color(var(--textColor) l(90%));
      }

      p {
        @extend reset;
        color: color(var(--textColor) l(60%));
        font-size: calc(var(--space) * 0.9);
        text-align: center;
      }

      div {
        margin: calc(var(--space) * 0.5) 0;
        text-align: center;
      }
    }

    &__title {
      clear: fix;
      position: relative;

      &:after {
        @media (--screen-640) {
          clear: none;
        }
      }

      h2 {
        @extend reset;
        @mixin textOverflow
          calc(var(--space) * 3), 2;

        @media (--screen-640) {
          padding-right: calc(var(--space) * 6);
        }
      }

      p {
        @mixin textOverflow
          calc(var(--space) * 3), 2;

        @media (--screen-640) {
          padding-right: calc(var(--space) * 6);
        }
      }

      .button {
        float: right;

        @media (--screen-640) {
          float: none;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    &__content {
      ul {
        list-style-position: inside;
      }
    }

    &__block {
      clear: both;

      h5 {
        font-size: calc(var(--fontSize) * 1.1);
        font-weight: bold;
      }
    }

    &__editor-note {
      background: var(--textColorLighter);
      color: color(var(--textColor) l(50%));
      padding: calc(var(--space) * 1.5);
      margin: var(--space) 0;

      @media (--screen-640) {
        margin: var(--space) 0;
      }

      h3 {
        @extend reset;
        font-size: var(--fontSize);
        font-weight: bold;
        margin-bottom: calc(var(--space) * 0.5);
      }
    }

    &__main-product {
      h4 {
        @extend reset;
        font-size: calc(var(--fontSize) * 1.1);
        font-weight: bold;
        margin-bottom: calc(var(--space) * 0.5);
      }

      p {
        @mixin textOverflow
          calc(var(--space) * 3), 2;
        font-size: calc(var(--fontSize) * 0.9);

        a {
          border-bottom: 1px solid transparent;
          color: color(var(--textColor) l(50%));
          font-weight: normal;

          &:--link {
            border-color: color(var(--textColor) l(50%));
            color: var(--textColor);
          }
        }
      }
    }

    div + div {

      @media (--screen-640) {
        margin-top: calc(var(--space) * 2);
      }
    }
  }
  /* supplier-product-detail end */


  /* supplier-product module start */
  &__line {
    clear: fix;

    &:nth-child(even) {

      @media (--screen-640) {
        background: var(--textColorLighter);
      }
    }
  }

  &__list {
    clear: fix;
    padding: calc(var(--space) * 2) var(--space);
    position: relative;

    @media (--screen-640) {
      lost-column: 1/2 0 0;
    }

    &:last-child {
      background: var(--textColorLighter);

      @media (--screen-640) {
        background: transparent;
      }
    }
  }

  .list__input {

    .list__checkbox {
      display: none;

      &:checked + .list__label:before {
        content: '\f046';
      }

      + .list__label {
        font-size: 0;

        &:before {
          @mixin fontAwesomeIcon
            var(--textColor), f096, 0;
          display: inline-block;
          font-size: calc(var(--fontSize) * 1.5);
          height: 30px;
          line-height: 30px;
          margin-top: -15px;
          position: absolute;
          top: 50%;
          width: 30px;
        }
      }
    }
  }

  .list__thumb {
    &-link {
      display: block;
    }

    &-img {
      @extend animate;
      border: 1px solid color(var(--textColor) l(90%));

      &:--link {
        border: 1px solid color(var(--textColor) l(80%));
      }
    }
  }

  .list__text {
    font-weight: bold;
    padding-bottom: var(--space);

    a {
      @extend animate;
      color: var(--textColor);
      font-size: calc(var(--fontSize) * 1.1);
      word-break: break-all;

      &:--link {
        color: var(--brandColorLighter);
      }
    }
  }

  .list__row {
    @extend reset;
    clear: both;
    color: color(var(--textColor) l(50%));
    padding: 0 calc(var(--space) * 1.5) 0;

    a {
      border-bottom: 1px solid transparent;
      color: color(var(--textColor) l(50%));

      &:--link {
        border-color: color(var(--textColor) l(50%));
        color: color(var(--textColor) l(50%));
      }
    }
  }
  /* supplier-product module end */
}