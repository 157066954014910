.pull {
  &--right {
    @media (--screen-640) {
      float: right;
    }
  }

  &--left {
    @media (--screen-640) {
      float: left;
    }
  }
}

.clearfix {
  clear: fix;
}

.grid {
  clear: fix;

  &--1-12,
  &--2-12,
  &--3-12,
  &--4-12,
  &--5-12,
  &--6-12,
  &--7-12,
  &--8-12,
  &--9-12,
  &--10-12,
  &--11-12,
  &--12-12 {

    + .grid--1-12,
    + .grid--2-12,
    + .grid--3-12,
    + .grid--4-12,
    + .grid--5-12,
    + .grid--6-12,
    + .grid--7-12,
    + .grid--8-12,
    + .grid--9-12,
    + .grid--10-12,
    + .grid--11-12,
    + .grid--12-12 {
      margin-top: var(--space);

      @media (--screen-768) {
        margin-top: 0;
      }
    }
  }

  &--1-12 { lost-column: 12/12; }
  &--2-12 { lost-column: 12/12; }
  &--3-12 { lost-column: 12/12; }
  &--4-12 { lost-column: 12/12; }
  &--5-12 { lost-column: 12/12; }
  &--6-12 { lost-column: 12/12; }
  &--7-12 { lost-column: 12/12; }
  &--8-12 { lost-column: 12/12; }
  &--9-12 { lost-column: 12/12; }
  &--10-12 { lost-column: 12/12; }
  &--11-12 { lost-column: 12/12; }
  &--12-12 { lost-column: 12/12; }

  @media (--screen-1300) {
    &--1-12 { lost-column: 1/12; }
    &--2-12 { lost-column: 2/12; }
    &--3-12 { lost-column: 3/12; }
    &--4-12 { lost-column: 4/12; }
    &--5-12 { lost-column: 5/12; }
    &--6-12 { lost-column: 6/12; }
    &--7-12 { lost-column: 7/12; }
    &--8-12 { lost-column: 8/12; }
    &--9-12 { lost-column: 9/12; }
    &--10-12 { lost-column: 10/12; }
    &--11-12 { lost-column: 11/12; }
    &--12-12 { lost-column: 12/12; }
  }
}