* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:focus {
  outline: 0;
}

::selection {
  background: var(--brandColorLighter);
  color: var(--bgColor);
}

html,
body {
  @extend reset;
  background: var(--textColorLighter);
  color: var(--textColor);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  @media (--print) {
    height: 100%;
    overflow: hidden;
  }
}

img,
svg {
  border: none;
  max-height: 100%;
  max-width: 100%;
}

.noscript-dialog {
  background: var(--highColor);
  color: var(--bgColor);
  padding: var(--space);
  text-align: center;

  h3 {
    color: var(--bgColor);
    margin: 0 0 var(--space) 0;
  }
}

.container {
  clear: fix;
  margin: 0 auto;

  &:before {
    clear: both;
    content: '';
    display: table;
  }

  @media (--screen-1300) {
    max-width: 1300px;
  }

  &--small {
    @media (--screen-1024) {
      width: 80%;
      margin: 0 auto;
    }
  }

  > .menu {
    @media (--screen-1024) {
      display: none;
    }
  }
}

.wrapper {

  &:before {
    background: #000;
    content: '';
    display: block;
    left: 0;
    height: 100vh;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100vw;
    z-index: 2001;
  }


  > .container {
    margin: var(--fixSpaceSmaller) auto;

    @media (--screen-640) {
      margin: var(--fixSpace) auto;
    }

    > div + div {
      margin-top: var(--fixSpaceSmaller);
    }
  }

  > .supplier-logo + .container {
    margin: var(--fixSpaceSmaller) auto var(--fixSpaceSmaller);

    @media (--screen-640) {
      margin: var(--fixSpaceSmaller) auto var(--fixSpace);
    }
  }
}