.table {
  border: none;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  overflow: auto;

  &__title {
    color: var(--brandColor);
    font-weight: bold;
  }

  td {
    padding: calc(var(--space) * 0.5);
    vertical-align: top;
  }
}