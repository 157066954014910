.page-code {
  padding: calc(var(--space) * 2) 0;
  clear: fix;

  &__row {
    @extend reset;
    list-style: none;
    text-align: center;
  }

  &__list {
    display: inline-block;

    &--active a {
      background: var(--brandColor);
      border-color: var(--brandColor);
      color: var(--bgColor);

      &:--link {
        background: var(--brandColor);
        border-color: var(--brandColor);
        color: var(--bgColor);
      }
    }
  }

  a {
    @extend animate;
    background: var(--bgColor);
    border-radius: 50%;
    border: 1px solid color(var(--textColor) l(50%));
    color: color(var(--textColor) l(50%));
    display: block;
    font-weight: bold;
    height: 36px;
    line-height: 36px;
    margin: calc(var(--space) * 0.2);
    text-align: center;
    width: 36px;

    @media (--screen-640) {
      height: 40px;
      line-height: 40px;
      width: 40px;
    }

    &:--link {
      background: color(var(--textColor) l(50%));
      color: var(--bgColor);
    }

    &.arrow {
      background: transparent;
      border-color: transparent;

      &:--link {
        background: transparent;
        color: var(--brandColorLighter);
      }
    }

    &.squircle {
      border-radius: calc(var(--space) * 0.7);
      height: auto;
      line-height: calc(var(--space) * 2.2);
      padding: 0 calc(var(--space) * 0.8);
      width: auto;

      @media (--screen-640) {
        height: auto;
        line-height: calc(var(--space) * 2.4);
        width: auto;
      }
    }
  }
}