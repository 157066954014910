.content {

  @media (--screen-1024) {
    lost-column: 9/12;
    lost-move: 3/12;
    max-width: 966px;
  }

  > div + div {
    margin-top: var(--fixSpaceSmaller);
  }
}