.product-box {
  @mixin productsList;
  @mixin productsListInput list;
  @mixin productsListThumb 5, 3;

  .list__thumb-link {
    position: relative;

    .list__time {
      border-radius: 0 0 0 var(--radius);
      background: var(--brandColorLighter);
      color: var(--bgColor);
      display: inline-block;
      padding: 0 calc(var(--space) * 0.5);
      position: absolute;
      right: 1px;
      top: 1px;
    }
  }

  .list__text {
    clear: fix;

    @media (--screen-640) {
      lost-column: 8/14;
    }

    @media (--screen-1024) {
      lost-column: 10/14;
    }

    &-product {
      .list__title {
        margin: calc(var(--space) * 0.5) 0 var(--space);

        a {
          @extend animate;
          @mixin textOverflow calc(var(--space) * 3), 2;
          color: var(--brandColorLighter);
          font-size: calc(var(--fontSize) * 1.1);

          &:--link {
            color: var(--textColor);
          }
        }
      }

      .list__info {
        @mixin textOverflow calc(var(--space) * 4.5), 3;

        @media (--screen-768) {
          margin: 0 0 calc(var(--space) * 3);
        }
      }

      .list__link {
        background: var(--highColor);

        &:--link {
          background: var(--textColor);
        }
      }

      @media (--screen-1024) {
        lost-column: 8/14;
      }
    }

    &-supplier {
      border: 1px solid color(var(--textColor) a(30%));
      border-width: 1px 0 0 0;
      margin-top: var(--space);
      padding: var(--space) 0 0 0;

      @media (--screen-1024) {
        border-width: 0 0 0 1px;
        lost-column: 6/14;
        margin-top: 0;
        padding: 0 0 0 var(--space);
      }

      .list__title {
        margin: calc(var(--space) * 0.5) 0 var(--space);

        a {
          @extend animate;
          @mixin textOverflow auto, 2;
          max-height: calc(var(--space) * 3);
          color: var(--brandColorLighter);
          font-size: calc(var(--fontSize) * 0.9);

          &:--link {
            color: var(--textColor);
          }
        }
      }

      h5 {
        font-size: calc(var(--fontSize) * 0.9);
        margin: calc(var(--space) * 2) 0 0;
      }

      p {
        @mixin textOverflow calc(var(--space) * 4.5), 3;
        font-size: calc(var(--fontSize) * 0.9);
        margin: 0;

        a {
          @extend animate;
          border-bottom: 1px solid transparent;
          color: var(--textColor);
          font-size: calc(var(--fontSize) * 0.8);

          &:--link {
            border-color: var(--textColor);
          }

          + a {
            margin-left: calc(var(--space) * 0.5);
          }
        }
      }

      .more {
        border-bottom: 1px solid var(--brandColorLighter);
        color: var(--brandColorLighter);
        font-size: calc(var(--fontSize) * 0.8);
      }
    }
  }
}