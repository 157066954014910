/* map-supplier-step start */
.map-supplier {
  &-step {
    &__row {
      @extend reset;
      clear: fix;
      list-style: none;
      margin: calc(var(--space) * 2) 0 0;

      li {
        border-radius: var(--radius);
        border: 1px solid var(--brandColorLighterBg);
        padding: calc(var(--space) * 0.5);
        position: relative;
        text-align: center;

        @media (--screen-768) {
          height: calc(var(--space) * 6.5);
          lost-column: 1/4;
        }

        + li {
          margin-top: var(--space);

          @media (--screen-768) {
            margin: 0;
          }

          &:before {
            @media (--screen-768) {
              content: '';
              border: 5px solid var(--brandColorLighter);
              border-color: transparent transparent transparent var(--brandColorLighter);
              left: -17px;
              height: 0;
              margin: -5px 0 0;
              position: absolute;
              top: 50%;
              width: 0;
            }
          }
        }
      }
    }

    &__title {
      background: var(--brandColorLighterBg);
      color: var(--brandColorLighter);
      font-weight: bold;
      padding: calc(var(--space) * 0.5) var(--space);
      margin: calc(var(--space) * -0.5) calc(var(--space) * -0.5) calc(var(--space) * 0.5);
    }
  }
}
/* map-supplier-step end */