.service-box {
  @extend shadow;
  background: var(--bgColor);
  clear: fix;
  display: block;
  margin-top: var(--fixSpaceSmaller);
  overflow: hidden;
  position: relative;

  @media (--screen-1024) {
    margin-top: 0;
  }

  h4 {
    @extend reset;
    color: var(--brandColor);
    float: left;
    font-size: calc(var(--fontSize) * 1.5);
    line-height: var(--space);
    padding: var(--space);
    width: 75%;

    p {
      @extend reset;
      color: var(--brandColorLighter);
      font-size: calc(var(--fontSize) * 1);
      margin-top: calc(var(--space) * 0.5);
    }
  }

  .button {
    background: color(var(--textColor) l(50%));
    border-radius: 0;
    float: left;
    margin: 0;
    text-align: center;
    width: 100%;

    @media (--screen-768) {
      line-height: calc(var(--space) * 5.5);
      width: 25%;
    }

    &:--link {
      background: var(--textColor);
    }
  }
}