.adv,
.adv-suppliers,
.googleads,
.pr {
  img {
    width: 100%;
  }
}

.adv,
.adv-page,
.adv-suppliers,
.googleads,
.pr {
  display: none;

  @media (--screen-1024) {
    display: block;
  }
}

.adv-suppliers {
  clear: fix;

  &__item {
    lost-column: 1/4;

    a {
      img {
        @extend animate;
        border: 1px solid transparent;
      }

      &:--link {
        img {
          border-color: color(var(--textColor) l(60%));
        }
      }
    }
  }
}

.adv-page {
  background: color(var(--textColor) l(88%));
  background:
    linear-gradient(
      to bottom,
      color(var(--textColor) l(88%)) 0%,
      color(var(--textColor) l(97%)) 100%
    );
  border: 1px solid color(var(--textColor) l(70%));
  height: 92px;
  overflow: hidden;

  @media (--screen-640) {
    text-align: center;
  }
}

.googleads {
  overflow: hidden;
}