.trade-leads {
  @extend shadow;
  background: var(--bgColor);
  clear: fix;
  display: none;

  @media (--screen-768) {
    display: block;
  }

  &__title {
    background: var(--brandColor);
    color: var(--bgColor);
    float: left;
    margin-right: -165px;
    padding: var(--space);
    position: absolute;
    text-align: center;
    width: 135px;

    &:after {
      border-bottom: 25px solid transparent;
      border-left: 27px solid var(--brandColor);
      border-top: 27px solid transparent;
      content: '';
      display: block;
      float: right;
      height: 0;
      position: absolute;
      right: -27px;
      top: 0;
      width: 0;
    }
  }

  &__list {
    clear: fix;
    margin-left: 165px;
    padding: var(--space);
  }

  &__item {
    white-space: nowrap;

    > a {
      @extend animate;
      color: var(--brandColorLighter);
      display: inline-block;
      font-weight: bold;
      lost-column: 3/12 0 0;
      overflow: hidden;

      &:--link {
        color: var(--textColor);
      }
    }

    p {
      lost-column: 9/12 0 0;
      margin: 0;
      overflow: hidden;

      &:before {
        border-top: 1px solid color(var(--textColor) l(70%));
        content: '';
        display: inline-block;
        height: 0;
        margin: 0 calc(var(--space) * 0.5);
        vertical-align: middle;
        width: calc(var(--space) * 2);
      }

      a {
        @extend animate;
        color: color(var(--textColor) l(60%));

        &:--link {
          color: var(--textColor);
        }
      }
    }
  }
}