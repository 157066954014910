/* image float start */
#img_left,
.img_left,
#img_right,
.img_right {
  display: block;
  margin: auto;
  max-width: 100%;

  @media (--screen-640) {
    max-width: 200px;
  }
}

#img_left,
.img_left {

  @media (--screen-640) {
    float: left;
    margin: auto 15px 10px auto;
  }
}

#img_right,
.img_right {

  @media (--screen-640) {
    float: right;
    margin: auto 5px 10px 15px;
  }
}
/* image float end */

.press-room {
  @mixin supplierDisplay;
  clear: fix;

  &-text {
    clear: fix;
  }
}