.or {
  @extend shadow;
  @extend animate;
  display: none;
  opacity: 0;

  &.slick-initialized {
    display: block;
    opacity: 1;
  }

  &:--link {
    .slick-arrow {
      opacity: 1;
    }

    .or__more {
      color: color(var(--highColor) h(+20));
      text-shadow: var(--shadow);
    }
  }

  &__img {
    width: 100%;
  }

  &__text {
    @extend reset;
    background: var(--textColor);
    color: var(--bgColor);
    padding: calc(var(--space) * 0.5);
    text-align: center;
  }

  &__more {
    @extend animate;
    color: var(--highColor);
    display: inline-block;
    font-size: calc(var(--fontSize) * 0.9);
    padding-left: calc(var(--space) * 1.1);

    &:before {
      @mixin fontAwesomeIcon
        inherit, f08e, 0 calc(var(--space) * 0.4) 0 0;
    }
  }

  .slick {
    &-arrow {
      @extend animate;
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 0;
      margin-top: calc(var(--fontSize) * -1.5);
      opacity: 0;
      position: absolute;
      top: 50%;
      z-index: 1000;

      @media (--screen-1024) {
        margin-top: calc(var(--fontSize) * -2);
      }

      &:before {
        @extend textShadow;
        font-size: calc(var(--fontSize) * 3);
        line-height: var(--space);

        @media (--screen-1024) {
          font-size: calc(var(--fontSize) * 4);
        }
      }
    }

    &-prev {
      left: calc(var(--fontSize) * 0.5);

      @media (--screen-1024) {
        left: var(--fontSize);
      }

      &:before {
        @mixin fontAwesomeIcon
          var(--bgColor), f104, 0;
      }
    }

    &-next {
      right: calc(var(--fontSize) * 0.5);

      @media (--screen-1024) {
        right: var(--fontSize);
      }

      &:before {
        @mixin fontAwesomeIcon
          var(--bgColor), f105, 0;
      }
    }

    &-dots {
      @extend reset;
      bottom: calc(var(--space) * 4.3);
      cursor: pointer;
      list-style: none;
      padding: 0 calc(var(--space) * 0.6);
      position: absolute;
      right: 0;
      text-align: right;
      z-index: 1000;

      @media (--screen-768) {
        bottom: calc(var(--space) * 3);
      }

      button {
        display: none;
      }

      li {
        @extend shadow;
        background: var(--textColorLighter);
        border-radius: 50%;
        display: inline-block;
        height: 10px;
        margin: 0 calc(var(--space) * 0.3);
        opacity: 0.5;
        width: 10px;
      }

      .slick-active {
        opacity: 1;
      }
    }
  }
}