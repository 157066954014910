.menu {
  @extend animate;
  background: var(--bgColor);
  box-shadow: none;
  height: 100vh;
  left: 0;
  margin-bottom: var(--fixSpaceSmaller);
  overflow: auto;
  position: fixed;
  top: 0;
  transform: translateX(-80vw);
  width: 80vw;
  z-index: 3000;

  @media (--screen-480) {
    transform: translateX(-60vw);
    width: 60vw;
  }

  @media (--screen-1024) {
    box-shadow: var(--shadow);
    height: auto;
    transform: none;
    overflow: visible;
    position: relative;
    width: auto;
    z-index: 1000;
  }

  @media (--print) {
    display: none;
  }

  &--open {
    overflow: hidden;
  }

  &--supplier {

    > li > a > span.sub-arrow:before {
      @media (--screen-1024) {
        transform: rotate(90deg);
      }
    }

    > li > a.highlighted span.sub-arrow:before {
      @media (--screen-1024) {
        transform: rotate(270deg);
      }
    }

    > li > ul {
      background: color(var(--textColor) l(95%));
      box-shadow: none;
      display: block;
      position: static;
      width: 100%;
    }
  }

  &__label {
    @extend animate;
    @extend shadow;
    background: var(--textColorLighter);
    border: 2px solid var(--textColorLighter);
    border-radius: var(--radius);
    color: var(--textColor);
    cursor: pointer;
    font-size: 0;
    text-align: center;
    position: absolute;
    margin-top: calc(var(--fontSize) * -3.5);
    white-space: nowrap;

    @media (--screen-640) {
      font-size: calc(var(--fontSize) * 0.9);
      line-height: calc(var(--space) * 3.3);
      lost-column: 3/12;
      margin-top: 0;
      position: static;
    }

    @media (--screen-1024) {
      display: none;
    }

    &:--link {
      background: var(--brandColorLighter);
      border-color: var(--brandColorLighter);
      color: var(--bgColor);
    }

    i {
      font-size: calc(var(--fontSize) * 0.9);
      padding: calc(var(--space) * 0.9) var(--space);

      @media (--screen-640) {
        margin: 0 calc(var(--space) * 0.2);
        padding: calc(var(--space) * 0.9) calc(var(--space) * 0.5);
      }

      @media (--screen-768) {
        margin: 0 calc(var(--space) * 0.5);
        padding: calc(var(--space) * 0.9) var(--space);
      }
    }
  }

  &__btn {
    display: none;

    &:checked {
      ~ .wrapper {
        &:before {
          opacity: 0.8;
          visibility: visible;

          @media (--screen-1024) {
            opacity: 0;
            visibility: none;
          }
        }

        .menu {
          box-shadow: 3px 0 20px rgba(0, 0, 0, 0.5);
          transform: none;
        }
      }
    }
  }

  &__row {
    background: var(--bgColor);
    border-top: 5px solid var(--highColor);
  }

  * {
    @extend reset;
  }
}