.content-box {
  @extend shadow;
  background: var(--bgColor);
  border-top: 5px solid var(--highColor);
  clear: fix;
  padding: calc(var(--space) * 1.5) calc(var(--space) * 1)
           calc(var(--space) * 2);
  position: relative;

  @media (--screen-768) {
    padding: calc(var(--space) * 3) calc(var(--space) * 2);
  }

  h3 {
    margin-top: 0;
    font-size: calc(var(--fontSize) * 1.5);

    @media (--screen-768) {
      font-size: calc(var(--fontSize) * 2);
    }

    span {
      color: var(--highColor);
    }
  }

  h4,
  h5 {
    @extend reset;
    color: var(--brandColorLighter);
    margin: calc(var(--space) * 1.5) 0 calc(var(--space) * 0.5);
  }

  /* content-box-slider */
  &-slider {
    font-size: calc(var(--fontSize) * 0.9);
    text-align: center;

    a {
      color: var(--textColor);
    }

    p {
      @mixin textOverflow
        calc(var(--space) * 3), 2;
    }

    &__img {
      padding: 0 calc(var(--space) * 0.5);
      width: 100%;
    }

    .slick {
      &-arrow {
        background: transparent;
        border: none;
        cursor: pointer;
        font-size: 0;
        margin-top: calc(var(--fontSize) * -1);
        position: absolute;
        top: 50%;
        z-index: 1000;

        &:before {
          @extend animate;
          font-size: calc(var(--fontSize) * 2);
          line-height: var(--space);
        }

        &:--link {
          &:before {
            color: var(--brandColor);
          }
        }
      }

      &-prev {
        left: 0;

        @media (--screen-640) {
          left: calc(var(--fontSize) * -1.5);
        }

        &:before {
          @mixin fontAwesomeIcon
            color(var(--textColor) l(70%)), f104, 0;
        }
      }

      &-next {
        right: 0;

        @media (--screen-640) {
          right: calc(var(--fontSize) * -1.5);
        }

        &:before {
          @mixin fontAwesomeIcon
            color(var(--textColor) l(70%)), f105, 0;
        }
      }
    }
  }

  /* content-box-text */
  &-text {
    clear: fix;

    &__item {
      lost-waffle: 1/1;

      @media (--screen-640) {
        lost-waffle: 1/2;
      }

      @media (--screen-768) {
        lost-waffle: 1/3;
      }
    }

    a {
      @extend animate;
      color: var(--brandColorLighter);
      font-weight: bold;

      &:--link {
        color: var(--brandColor);
      }
    }

    p {
      @mixin textOverflow calc(var(--space) * 3), 2;
      font-size: calc(var(--fontSize) * 0.9);

      a {
        border-bottom: 1px solid transparent;
        color: color(var(--textColor) l(50%));
        font-weight: normal;

        &:--link {
          border-color: color(var(--textColor) l(50%));
          color: var(--textColor);
        }
      }
    }
  }

  /* content-box-static */
  &-static {
    clear: fix;
    font-size: calc(var(--fontSize) * 0.9);
    text-align: center;

    &__item {
      lost-waffle: 1/2;

      @media (--screen-768) {
        lost-column: 3/12;
      }
    }

    &__img {
      padding: 0 calc(var(--space) * 0.5);
      width: 100%;
    }

    a {
      color: var(--textColor);
    }

    p {
      @mixin textOverflow
        calc(var(--space) * 3), 2;
    }
  }
}