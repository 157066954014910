.collapse-box {
  &.js-collapse-box .collapse-box__head {
    cursor: pointer;
  }

  &__head {
    @extend animate;
    background: var(--textColorLighter);
    border-left: 5px solid var(--textColor);
    font-weight: bold;
    margin: calc(var(--space) * 0.5) 0;
    padding: calc(var(--space) * 0.5) calc(var(--space) * 0.5) calc(var(--space) * 0.5) var(--space);

    &:--link,
    &--active {
      background: color(var(--brandColorLighter) l(90%));
      border-color: var(--brandColorLighter);
      color: var(--brandColorLighter);
    }
  }

  &__content {
    @mixin insideBoxTextMarginTop;
    height: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &--active {
      padding: var(--space) 0;
      height: auto;

      @media (--screen-768) {
        padding: var(--space);
      }
    }
  }
}