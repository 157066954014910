.backtotop {
  background: color(var(--bgColor) a(70%));
  bottom: calc(var(--space) * 0.5);
  color: var(--brandColor);
  cursor: pointer;
  font-size: calc(var(--fontSize) * 1.2);
  padding: calc(var(--space) * 0.5) var(--space);
  position: fixed;
  text-align: center;
  right: calc(var(--space) * 0.5);
  z-index: 2000;

  @media (--screen-640) {
    bottom: calc(var(--space) * 2);
    right: calc(var(--space) * 2);
  }

  i {
    @extend animate;
  }

  &--active {
    i {
      transform: rotate(180deg);
    }
  }
}