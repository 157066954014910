.toggle-list {
  @extend reset;
  @extend animate;
  color: var(--brandColorLighter);
  cursor: pointer;
  font-size: var(--fontSize);
  font-weight: bold;

  &:--link {
    color: var(--textColor);
  }

  &:after {
    @mixin fontAwesomeIcon
      inherit, f146, 0 0 0 var(--space);
    font-weight: normal;
  }

  &--close:after {
    content: '\f0fe';
  }
}