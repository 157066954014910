@import 'base/variables';
@import 'base/selector';

/* ------------------------------- plugins ------------------------------ */
@import '../../node_modules/font-awesome/css/font-awesome.css';
@import '../../node_modules/slick-carousel/slick/slick.css';
@import '../../node_modules/jquery-nice-select/css/nice-select.css';
@import '../../node_modules/smartmenus/dist/css/sm-core-css.css';
@import '../../node_modules/animate.css/animate.css';

/* -------------------------------- mixin ------------------------------- */
@import 'mixin/extend';
@import 'mixin/mixin';
@import 'mixin/breakpoint';
@import 'mixin/animate';

/* ------------------------------- layout ------------------------------- */
@import 'layout/base';
@import 'layout/header';
@import 'layout/menu';
@import 'layout/footer';
@import 'layout/sidebar';
@import 'layout/content';
@import 'layout/preloader';
@import 'layout/backtotop';

/* ------------------------------- module ------------------------------- */
@import 'module/adv-banner';
@import 'module/breadcrumb';
@import 'module/collapse-box';
@import 'module/content-box';
@import 'module/form';
@import 'module/grid';
@import 'module/notice';
@import 'module/page-code';
@import 'module/panel-box';
@import 'module/rating';
@import 'module/service-box';
@import 'module/sidebar-box';
@import 'module/tab-box';
@import 'module/table';
@import 'module/table-box';
@import 'module/text';
@import 'module/toggle-list';

/* ---------------------------- custom plugin --------------------------- */
@import 'plugin/sm-custom';

/* -------------------------------- page -------------------------------- */
/* page index start */
@import 'page/index/big-three';
@import 'page/index/or';
@import 'page/index/trade-leads';
/* page index end */

/* page product-list start */
@import 'page/product-list/bar-inquiry';
@import 'page/product-list/category-box';
@import 'page/product-list/product-box';
@import 'page/product-list/supplier-box';
@import 'page/product-list/rs';
/* page product-list end */

/* page supplier start */
@import 'page/supplier/index';
@import 'page/supplier/product';
@import 'page/supplier/content';
/* page supplier end */

@import 'page/aboutus';
@import 'page/inquiry';
@import 'page/link-exchange';
@import 'page/map-supplier';
@import 'page/more-new-supplier';
@import 'page/notfound';
@import 'page/press-room';
@import 'page/register';
@import 'page/userguide';