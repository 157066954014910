.user-guide {
  &__row {
    @extend reset;
    clear: fix;
    list-style: none;
  }

  &__item {
    lost-waffle: 1/2;

    span {
      display: block;
    }

    @media (--screen-1024) {
      lost-waffle: 1/3;
    }
  }
}