.notice {
  border-radius: var(--radius);
  margin: var(--space) 0;
  padding: var(--space);

  &--default {
    background: var(--brandColorLighterBg);
    border: 1px solid color(var(--brandColorLighterBg) l(-5%));
  }

  &--warning {
    background: var(--highColorBg);
    border: 1px solid color(var(--highColorBg) l(-5%));
  }

  @mixin insideBoxTextMarginTop;
}