.link-box {
  &__row {
    @extend reset;
    clear: fix;
    list-style: none;
  }

  &__item {
    lost-waffle: 1/1;

    @media (--screen-768) {
      lost-waffle: 1/2;
    }

    @media (--screen-1024) {
      lost-waffle: 1/3;
    }
  }
}

.link-exchange__step {
  label {

    @media (--screen-768) {
      lost-column: 2/12;
    }
  }
  .form__content {
    line-height: calc(var(--space) * 1.5);

    @media (--screen-768) {
      line-height: calc(var(--space) * 2.25);
      lost-column: 9/12;
    }
  }
}