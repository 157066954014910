.link-box {
  clear: fix;

  &__item {
    lost-waffle: 1/2;

    @media (--screen-1024) {
      lost-waffle: 1/3;
    }
  }
}

.member-box {
  clear: fix;

  &__item {
    @media (--screen-768) {
      lost-column: 6/12 2;
    }
  }

  &__head {
    font-weight: bold;
    padding: calc(var(--space) * 0.5);
    text-align: center;

    &--silver {
      background: color(var(--textColor) l(80%));
    }

    &--golden {
      background: color(var(--highColor) l(70%));
    }
  }

  &__content {
    padding: var(--space) calc(var(--space) * 0.5);

    @media (--screen-768) {
      height: calc(var(--space) * 21);
    }

    @media (--screen-1300) {
      height: calc(var(--space) * 18);
    }

    &--silver {
      background: var(--textColorLighter);
    }

    &--golden {
      background: color(var(--highColor) l(90%));
    }

    ul {
      margin: 0;
    }
  }
}