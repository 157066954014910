.supplier-content {
  &-block {
    clear: both;
  }

  &-img {
    @media (--screen-768) {
      lost-column: 4/12;
    }

    a {
      display: block;
      text-align: center;
    }

    img {
      border: 1px solid color(var(--textColor) l(90%));
    }

    p {
      @extend reset;
      color: color(var(--textColor) l(60%));
      font-size: calc(var(--space) * 0.9);
      text-align: center;
    }

    div {
      margin: calc(var(--space) * 0.5) 0;
      text-align: center;
    }
  }

  &-text {

    h2 {
      @mixin textOverflow
        calc(var(--space) * 3), 2;
    }

    h5 {
      @extend reset;
      font-size: calc(var(--fontSize) * 1.1);
      font-weight: bold;
      margin-bottom: calc(var(--space) * 0.5);
    }
  }
}