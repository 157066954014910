.notfound {
  &__search-google {
    margin-bottom: calc(var(--space) * 2);

    h3 {
      @extend reset;
      color: var(--brandColorLighter);
      font-size: var(--fontSize);
      margin: calc(var(--space) * 1.5) 0 calc(var(--space) * 0.5);
    }

    .content {
      float: none;
      margin: auto;
      max-width: 100%;
      position: static;
      width: 100%;

      input + input {
        @media (--screen-640) {
          margin-left: var(--space);
        }
      }
    }
  }

  &__info {
    background: color(var(--highColor) l(90%));
    margin: 0 calc(var(--space) * -2) calc(var(--space) * -3);
    padding: calc(var(--space) * 2);

    span {
      color: var(--highColor);
      font-size: calc(var(--fontSize) * 0.9);
      font-weight: bold;
    }
  }
}