@define-extend reset {
  margin: 0;
  padding: 0;
}

@define-extend animate {
  transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

@define-extend shadow {
  box-shadow: var(--shadow);
}

@define-extend textShadow {
  text-shadow: var(--shadow);
}

@define-extend textOverflow {
  text-overflow: ellipsis;
  overflow : hidden;
  -webkit-box-orient: vertical;
}