@define-mixin fontAwesomeIcon $color, $icon, $padding {
  color: $color;
  content: '\$icon';
  display: inline-block;
  padding: $padding;
  font-family: fontAwesome;
}

@define-mixin textOverflow $height, $line {
  @extend textOverflow;
  max-height: $height;
  -webkit-line-clamp: $line;
}

@define-mixin productsList {
  &__list {
    clear: fix;
    padding: calc(var(--space) * 4) var(--space)
             calc(var(--space) * 2);
    position: relative;

    @media (--screen-640) {
      padding: calc(var(--space) * 2) var(--space);
    }

    &:nth-child(even) {
      background: var(--textColorLighter);
    }
  }
}

@define-mixin productsListInput $item {
  .$(item)__input {
    left: calc(var(--space) * 1.2);
    lost-column: 1/14 0 0;
    position: absolute;
    top: calc(var(--space) * 1.5);
    z-index: 1;

    @media (--screen-640) {
      left: auto;
      height: calc(var(--space) * 12);
      position: relative;
    }

    @media (--screen-1300) {
      height: calc(var(--space) * 15);
    }

    .$(item)__checkbox {
      display: none;

      &:checked + .$(item)__label:before {
        content: '\f046';
      }

      + .$(item)__label {
        font-size: 0;
        margin-top: var(--space);

        &:before {
          @mixin fontAwesomeIcon
            var(--textColor), f096, 0;
          font-size: calc(var(--fontSize) * 1.5);
          height: 30px;
          left: 50%;
          line-height: 30px;
          margin: -15px 0 0 -15px;
          position: absolute;
          top: 50%;
          width: 30px;
        }
      }
    }
  }
}

@define-mixin productsListThumb $s, $b {
  .list__thumb {
    &-link {
      display: block;

      @media (--screen-640) {
        lost-column: $s/14;
      }

      @media (--screen-1024) {
        lost-column: $b/14;
      }
    }

    &-img {
      @extend animate;
      border: 1px solid color(var(--textColor) l(90%));
      width: 100%;

      &:--link {
        border: 1px solid color(var(--textColor) l(80%));
      }
    }
  }
}

@define-mixin supplierDisplay $imgs: 4, $imgb: 3, $texts: 10, $textb: 11 {
  &__list {
    clear: fix;
    padding: calc(var(--space) * 1.5) var(--space);

    @media (--screen-768) {
      padding: calc(var(--space) * 2);
    }

    &:nth-child(even) {
      background: var(--textColorLighter);
    }
  }

  .list__thumb {
    &-link {
      display: none;

      @media (--screen-640) {
        display: block;
        lost-column: $imgs/14;
      }

      @media (--screen-1024) {
        lost-column: $imgb/14;
      }
    }

    &-img {
      @extend animate;
      border: 1px solid color(var(--textColor) l(90%));
      width: 100%;

      &:--link {
        border: 1px solid color(var(--textColor) l(80%));
      }
    }
  }

  .list__text {
    clear: fix;

    @media (--screen-640) {
      lost-column: $texts/14;
    }

    @media (--screen-1024) {
      lost-column: $textb/14;
    }

    .list__title {
      @mixin textOverflow calc(var(--space) * 3), 2;
      font-size: calc(var(--fontSize) * 1.1);
      margin: 0 0 var(--space);

      a {
        @extend animate;
        color: var(--brandColorLighter);
        margin-right: calc(var(--space) * 1.5);

        &:--link {
          color: var(--textColor);
        }
      }

      .supplier-level {
        display: inline-block;
        display: inline-table;
        font-size: var(--fontSize);

        i {
          padding-right: calc(var(--space) * 0.5);
        }

        &--golden {
          color: var(--highColor);
        }

        &--sliver {
          color: color(var(--textColor) l(60%));
        }
      }
    }

    .list__info {
      @mixin textOverflow calc(var(--space) * 4.5), 3;
      margin: 0 0 calc(var(--space) * 2);
    }

    h6 {
      font-size: var(--fontSize);
      margin: 0;
    }

    p {
      font-size: var(--fontSize);
      margin: 0;

      &:nth-child(4) {
        font-size: calc(var(--fontSize) * 0.9);
      }

      a {
        @extend animate;
        border-bottom: 1px solid transparent;
        color: var(--textColor);

        &:--link {
          border-color: var(--textColor);
        }

        + a {
          margin-left: calc(var(--space) * 0.5);
        }
      }
    }

    .list__link {
      background: var(--highColor);
      color: var(--bgColor);
      font-size: var(--fontSize);

      &:--link {
        background: var(--textColor);
      }
    }

    .more {
      border-bottom: 1px solid var(--brandColorLighter);
      color: var(--brandColorLighter);
      font-size: calc(var(--fontSize) * 0.9);
    }
  }
}

@define-mixin insideBoxTextMarginTop $m: 0.2 {
  > h1:first-child,
  > h2:first-child,
  > h3:first-child,
  > h4:first-child,
  > h5:first-child,
  > h6:first-child {
    margin-top: calc(var(--space) * $m);
  }
}