.big-three {
  clear: fix;

  h3 {
    color: var(--brandColor);
    font-size: calc(var(--fontSize) * 1.2);
    line-height: calc(var(--space) * 1.2);
    margin: 0;
    position: relative;

    @media (--screen-768) {
      font-size: calc(var(--fontSize) * 2);
    }
  }

  p {
    color: color(var(--textColor) l(50%));
    font-size: calc(var(--fontSize) * 0.9);
    position: relative;
    margin: calc(var(--space) * 0.5) 0;

    @media (--screen-768) {
      margin: var(--space) 0;
    }
  }

  &__item {
    @extend shadow;
    background: var(--bgColor);
    lost-column: 1/1;
    overflow: hidden;
    padding: calc(var(--space) * 1.5) var(--space);
    position: relative;

    @media (--screen-768) {
      lost-column: 1/3;
      height: calc(var(--space) * 16);
      padding: calc(var(--space) * 2);
    }

    @media (--screen-1300) {
      height: calc(var(--space) * 13);
    }

    + .big-three__item {
      border-top: 1px dotted var(--textColorMiddle);

      @media (--screen-768) {
        border-top: none;
      }
    }
  }

  &__icon {
    bottom: calc(var(--space) * -0.3);
    color: var(--textColorLighter);
    font-size: calc(var(--fontSize) * 7);
    position: absolute;
    right: calc(var(--space) * 0.1);
  }
}