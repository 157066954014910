.supplier-index {
  &__img {
    position: relative;

    img {
      vertical-align: bottom;
      width: 100%;
    }

    p {
      @extend reset;
      background: rgba(0, 0, 0, 0.6);
      bottom: 0;
      color: var(--bgColor);
      left: 0;
      padding: calc(var(--space) * 0.5);
      position: absolute;
      right: 0;
      text-align: center;
    }

    &--float {
      lost-column: 8/12;

      @media (--screen-480) {
        lost-column: 6/12;
      }
    }

    &--big {
      margin: 0 auto calc(var(--space) * 2);

      @media (--screen-768) {
        width: 90%;
      }
    }
  }
}