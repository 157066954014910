.sidebar-box {
  @extend shadow;
  background: var(--bgColor);
  clear: fix;
  display: block;
  margin-top: var(--fixSpaceSmaller);
  overflow: hidden;
  padding: calc(var(--space) * 1.5);
  position: relative;

  @media (--screen-768) {
    padding: calc(var(--space) * 2);
  }

  @media (--screen-1024) {
    margin-top: 0;
    padding: calc(var(--space) * 1.5) var(--space) calc(var(--space) * 1.5);
  }

  &__icon {
    color: var(--textColorLighter);
    bottom: calc(var(--space) * -0.3);
    font-size: calc(var(--fontSize) * 7);
    right: calc(var(--space) * 0.1);
    position: absolute;
  }

  &__title {
    font-weight: bold;

    @media (--screen-1300) {
      lost-column: 3/12 0 0;
    }
  }

  &__content {
    @media (--screen-1300) {
      lost-column: 9/12 0 0;
    }
  }

  h4 {
    color: var(--brandColor);
    font-size: calc(var(--fontSize) * 1.5);
    line-height: var(--space);
    margin: 0;

    @media (--screen-768) {
      font-size: calc(var(--fontSize) * 2);
    }
  }

  ul {
    color: color(var(--textColor) l(50%));
    font-size: calc(var(--space) * 0.9);
    padding-left: calc(var(--space) * 2.5);

    li {
      clear: fix;

      + li {
        padding-top: calc(var(--space) * 0.5);
      }
    }
  }

  .button {
    background: var(--highColor);
    position: relative;

    &:--link {
      background: var(--textColor);
    }
  }

  /* sidebar-box-contact start */
  &-contact {
    ul {
      list-style: none;
      padding-left: 0;
    }

    li:first-child {
      color: var(--brandColorLighter);
      font-size: calc(var(--fontSize) * 1.1);
      font-weight: bold;
      margin-bottom: calc(var(--space) * 0.5);
    }
  }
  /* sidebar-box-contact end */
}