.category-box {
  @mixin productsList;
  @mixin productsListInput list;

  &__list {
    padding: var(--space);
  }

  .list {
    &__input {
      @media (--screen-640) {
        height: auto;
      }
    }

    &__title--category {
      a {
        &:last-child {
          color: var(--textColor);
          font-weight: bold;
          pointer-events: none;
        }

        + a:before {
          color: var(--textColor);
          content: '/';
          font-weight: normal;
          padding: 0 calc(var(--space) * 0.5);
          top: 8px;
        }
      }
    }

    &__text {
      lost-column: 11/14;
      margin-left: calc(var(--space) * 3);

      @media (--screen-768) {
        lost-column: 8/14;
        margin-left: 0;
      }

      @media (--screen-1300) {
        lost-column: 9/14;
      }
    }

    &__count {
      text-align: right;

      @media (--screen-768) {
        lost-column: 5/14;
      }

      @media (--screen-1300) {
        lost-column: 4/14;
      }

      .button {
        background: transparent;
        color: var(--brandColor);
        padding: calc(var(--space) * 0.5);
        white-space: nowrap;

        @media (--screen-640) {
          padding: calc(var(--space) * 0.5) var(--space);
        }

        &:--link {
          background: var(--brandColorLighter);
          color: var(--bgColor);
        }

        &__badge {
          background: var(--brandColor);
          color: var(--bgColor);
        }

      }
    }

    &__input {
      height: calc(var(--space) * 3.5);

      @media (--screen-1300) {
        height: calc(var(--space) * 3.5);
      }
    }
  }
}