.rating {
  @extend reset;
  border: none;
  clear: fix;

  > div {
    float: left;
  }

  &__title {
    margin-right: calc(var(--space) * 0.5);
  }

  &__star {
    white-space: nowrap;

    a {
      cursor: default;
    }

    span {
      color: var(--textColorMiddle);

      &:before {
        content: '\f005';
        font-family: fontAwesome;
      }

      + span {
        margin-left: calc(var(--space) * 0.5);
      }
    }

    &[rating='5'] span:nth-child(-n+5),
    &[rating='4'] span:nth-child(-n+4),
    &[rating='3'] span:nth-child(-n+3),
    &[rating='2'] span:nth-child(-n+2),
    &[rating='1'] span:nth-child(-n+1) {
      color: var(--highColor);
    }
  }

  &__btn {
    display: block;
    margin-left: var(--space);

    button {
      @extend reset;
      background: var(--bgColor);
      border: 1px solid var(--textColorMiddle);
      color: var(--textColorMiddle);
      height: calc(var(--space) * 1.5);
      text-align: center;
      width: calc(var(--space) * 1.5);

      &:--link {
        background: var(--textColor);
        border-color: var(--textColor);
        color: var(--bgColor);
      }
    }
  }
}