.tab-box {

  &--small {
    box-shadow: none;
    margin-top: var(--space);

    .tab-box__head {
      border-bottom: none;
      white-space: normal;
    }

    .tab-box__body {
      box-shadow: none;
      padding: calc(var(--space) * 1.5) var(--space) 0 var(--space);
    }

    .tab-box__list {
      font-size: calc(var(--fontSize) * 1.1);
      margin: 0 calc(var(--space) * 0.5) calc(var(--space) * 0.5) 0;
      padding: calc(var(--space) * 0.3) var(--space);
      width: auto;

      a {
        padding: calc(var(--space) * 0.3) calc(var(--space) * 0.5);
        margin: calc(var(--space) * -0.3) calc(var(--space) * -1);
      }
    }
  }

  &.js-tab-box .tab-box__list {
    cursor: pointer;
  }

  &__head {
    @extend reset;
    border-bottom: 3px solid var(--brandColor);
    clear: fix;
    list-style: none;
    overflow-x: auto;
    white-space: nowrap;
  }

  &__list {
    @extend animate;
    background: var(--textColorMiddle);
    color: var(--bgColor);
    display: inline-block;
    font-size: calc(var(--fontSize) * 1.2);
    font-weight: bold;
    margin: 0;
    padding: calc(var(--space) * 0.3) calc(var(--space) * 1.5);
    text-align: center;
    vertical-align: bottom;
    width: auto;

    @media (--screen-768) {
      float: left;
      font-size: calc(var(--fontSize) * 1.5);
    }

    + .tab-box__list {
      margin-left: -4px;

      @media (--screen-768) {
        margin-left: 0;
      }
    }


    &:--link {
      background: var(--brandColorLighter);
    }

    &--active {
      background: var(--brandColor);
    }

    a {
      color: var(--bgColor);
      display: block;
      padding: calc(var(--space) * 0.3) calc(var(--space) * 1.5);
      margin: calc(var(--space) * -0.3) calc(var(--space) * -1.5);

      &:--link {
        color: var(--bgColor);
      }
    }
  }

  &__body {
    @extend shadow;
    background: var(--bgColor);
  }

  &__content {
    @extend animate;
    height: 0;
    opacity: 0;
    overflow: hidden;

    &--active {
      height: auto;
      opacity: 1;
    }
  }

  &-keyword {
    ul {
      @extend reset;
      clear: fix;
      list-style: none;
      padding: calc(var(--space) * 2) var(--space);

      @media (--screen-768) {
        padding: calc(var(--space) * 2);
        padding-left: calc(var(--space) * 4);
      }
    }

    li {
      lost-column: 1/2;
      margin: 0 auto calc(var(--space) * 0.5);

      @media (--screen-768) {
        lost-column: 1/3;
        margin: 0 auto calc(var(--space) * 0.8);
      }
    }
  }

  &-result {
    background: var(--textColor);
    color: var(--bgColor);
    padding: var(--space);

    span {
      color: var(--highColor);
      font-weight: bold;
    }
  }

  .content-box & .tab-box__body {
    padding: calc(var(--space) * 1.5) 0 0 0;
  }
}