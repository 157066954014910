.panel-box {
  @extend shadow;
  background: var(--bgColor);

  h3,
  h4 {
    background: var(--brandColor);
    color: var(--bgColor);
    font-weight: bold;
    margin: 0;
    padding: calc(var(--space) * 0.3);
    text-align: center;
  }

  h3 {
    font-size: calc(var(--fontSize) * 1.5);

    @media (--screen-768) {
      font-size: calc(var(--fontSize) * 2);
    }
  }

  h4 {
    font-size: calc(var(--fontSize) * 1);
    padding: calc(var(--space) * 1);
    text-align: left;

    span {
      color: var(--highColor);
    }
  }

  h5 {
    background: var(--brandColorLighterBg);
    font-size: var(--fontSize);
    padding: calc(var(--space) * 0.5) var(--space);
  }

  h5,
  p {
    margin: calc(var(--space) * 0.5) 0;
  }

  &__body {
    clear: fix;
  }

  &__content {
    padding: var(--space);

    &--login {
      ul {
        @extend reset;
        list-style: none;
      }

      .button {
        width: 100%;
      }
    }
  }

  &__badge {
    @extend animate;
    background: var(--brandColorLighter);
    border-radius: var(--radius);
    color: var(--bgColor);
    display: inline-block;
    margin-right: calc(var(--space) * 0.3);
    padding: calc(var(--space) * 0.1) calc(var(--space) * 0.5);

    &:--link {
      background: var(--textColor);
    }
  }

  /* panel-box-row start */
  &-row {
    @extend reset;
    padding-left: calc(var(--space) * 1.5);

    &__title {
      background: var(--brandColorLighterBg);
      padding: calc(var(--space) * 0.5);
    }
  }
  /* panel-box-row end */

  /* panel-box-keyword start */
  &-keyword {
    ul {
      @extend reset;
      clear: fix;
      list-style: none;
      padding: var(--space);

      @media (--screen-640) {
        padding: calc(var(--space) * 2) var(--space);
      }

      @media (--screen-768) {
        padding-left: calc(var(--space) * 4);
      }
    }

    li {
      margin-bottom: calc(var(--space) * 0.5);

      @media (--screen-640) {
        lost-column: 1/2;
      }

      @media (--screen-768) {
        lost-column: 1/3;
      }
    }
  }
  /* panel-box-keyword end */

  /* panel-box-categories start */
  &-categories {
    clear: fix;
    font-size: calc(var(--fontSize) * 0.9);
    padding: calc(var(--space) * 2) calc(var(--space) * 1.5);
    text-align: center;

    @media (--screen-768) {
      padding: calc(var(--space) * 3) calc(var(--space) * 2);
    }

    &__item {
      lost-waffle: 1/2;

      @media (--screen-640) {
        lost-waffle: 1/3;
      }

      @media (--screen-768) {
        lost-waffle: 1/4;
      }
    }

    &__img {
      padding: 0 calc(var(--space) * 0.5);
    }

    a {
      color: var(--textColor);
    }

    p {
      @mixin textOverflow
        calc(var(--space) * 3), 2;
    }
  }
  /* panel-box-categories end */

  /* panel-box-table start */
  &-table {
    ul {
      @extend reset;
      list-style: none;
    }

    > ul {
      padding: calc(var(--space) * 1.5);

      @media (--screen-768) {
        padding: calc(var(--space) * 2);
      }

      > li {
        clear: fix;

        + li {
          margin-top: calc(var(--space) * 0.5);
        }
      }
    }

    &__title {
      font-weight: bold;

      @media (--screen-1300) {
        lost-column: 3/12 0 0;
      }
    }

    &__content {
      @media (--screen-1300) {
        lost-column: 9/12 0 0;
      }

      .social {

        li {
          display: inline-block;
          margin-right: var(--space);
        }

        img,
        svg {
          margin-right: calc(var(--space) * 0.5);
          vertical-align: middle;
          width: 20px;
        }
      }
    }
  }
  /* panel-box-table end */



  &--float {
    &-big {
      @media (--screen-1024) {
        lost-column: 9/12;
      }

    }

    &-small {

      @media (--screen-1024) {
        lost-column: 3/12;
      }
    }
  }
}

.wrapper > .container > div + div.panel-box--float-big,
.wrapper > .container > div + div.panel-box--float-small {
  @media (--screen-1024) {
    margin-top: 0;
  }
}