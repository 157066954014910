.ns {
  clear: fix;

  &__list {
    clear: fix;
    padding: calc(var(--space) * 2) var(--space);

    @media (--screen-1024) {
      lost-column: 1/2 0 0;
      padding: calc(var(--space) * 2);
    }

    &:nth-child(even) {
      background: var(--textColorLighter);

      @media (--screen-1024) {
        background: transparent;
      }
    }

    &:nth-child(4n),
    &:nth-child(4n-1) {
      @media (--screen-1024) {
        background: var(--textColorLighter);
      }
    }
  }

  .list__thumb {
    &-link {
      display: block;

      @media (--screen-768) {
        lost-column: 3/14;
      }
    }

    &-img {
      @extend animate;
      border: 1px solid color(var(--textColor) l(90%));
      width: 100%;

      &:--link {
        border: 1px solid color(var(--textColor) l(80%));
      }
    }
  }

  .list__text {
    clear: fix;

    @media (--screen-768) {
      lost-column: 6/14;
    }

    .list__title {
      @mixin textOverflow calc(var(--space) * 3), 2;
      height: calc(var(--space) * 3);
      font-size: calc(var(--fontSize) * 1.1);
      margin: 0 0 var(--space);

      a {
        @extend animate;
        color: var(--brandColorLighter);
        margin-right: calc(var(--space) * 1.5);

        &:--link {
          color: var(--textColor);
        }
      }

      .supplier-level {
        display: inline-block;
        font-size: var(--fontSize);

        i {
          padding-right: calc(var(--space) * 0.5);
        }

        &--golden {
          color: var(--highColor);
        }

        &--sliver {
          color: color(var(--textColor) l(60%));
        }
      }
    }

    .list__info {
      @mixin textOverflow calc(var(--space) * 4.5), 3;
      height: calc(var(--space) * 4.5);
      font-size: var(--fontSize);
      margin: 0 0 calc(var(--space) * 2);
    }

    .list__category {
      @mixin textOverflow calc(var(--space) * 4.5), 3;
      height: calc(var(--space) * 4.5);
      margin: 0;
    }
  }

  .list__product {

    @media (--screen-768) {
      lost-column: 5/14;
    }

    h6 {
      background: var(--brandColorLighterBg);
      color: var(--brandColorLighter);
      font-size: var(--fontSize);
      margin: 0 0 var(--space) 0;
      padding: calc(var(--space) * 0.5) var(--space);
    }

    p {
      @mixin textOverflow calc(var(--space) * 4.5), 3;
      font-size: calc(var(--fontSize) * 0.9);
      margin: 0;
      padding: 0 var(--space);

      a {
        @extend animate;
        border-bottom: 1px solid transparent;
        color: var(--textColor);

        &:--link {
          border-color: var(--textColor);
        }

        + a {
          margin-left: calc(var(--space) * 0.5);
        }
      }
    }

    .list__link {
      background: var(--highColor);
      color: var(--bgColor);
      font-size: var(--fontSize);

      &:--link {
        background: var(--textColor);
      }
    }

    .more {
      border-bottom: 1px solid var(--brandColorLighter);
      color: var(--brandColorLighter);
      display: inline-block;
      font-size: calc(var(--fontSize) * 0.9);
      margin: var(--space) var(--space) 0;
    }
  }
}