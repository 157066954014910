.sidebar {
  width: 100%;

  @media (--screen-1024) {
    max-width: 300px;
    lost-column: 3/12;
    lost-move: -9/12 2;
  }

  @media (--print) {
    display: none;
  }

  > div + div {
    margin-top: var(--fixSpaceSmaller);
  }
}