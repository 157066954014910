header {
  background: var(--brandColor);
  clear: fix;
  padding: 0 var(--space);

  @media (--screen-1300) {
    padding: 0;
  }

  @media (--print) {
    display: none;
  }

  * {
    @extend reset;
  }

  > .container {
    &:last-child {
      padding-bottom: var(--space);
    }
  }

  /* .&--fixed {
    animation-duration: 1s;
    animation-name: header-fadeInDown;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.5);
    opacity: 1;
    padding-bottom: var(--space);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;

    .header-info,
    .supplier-logo {
      display: none;
    }
  } */

  .header-info {
    position: relative;

    &:after,
    &:before {
      background: var(--brandColor);
      bottom: 0;
      content: ' ';
      padding: 0 calc(var(--space) * 0.5);
      position: absolute;
      top: 0;
    }

    &:after {
      background: var(--brandColor);
      background: linear-gradient(to right,
                                  color(var(--brandColor) a(0%)),
                                  color(var(--brandColor) a(100%)));
      right: calc(var(--space) * -0.3);
    }

    &:before {
      background: var(--brandColor);
      background: linear-gradient(to left,
                                  color(var(--brandColor) a(0%)),
                                  color(var(--brandColor) a(100%)));
      left: calc(var(--space) * -0.3);
    }

    &__row {
      @extend reset;
      overflow-x: auto;
      text-align: right;
      white-space: nowrap;
      z-index: 1000;

      @media (--screen-640) {
        overflow-x: visible;
      }

      &.sm ul {
        @extend shadow;
        position: absolute;

        a {
          background: var(--bgColor);
          color: var(--textColor);
          padding: calc(var(--space) * 0.8);

          &:--link {
            background: color(var(--textColor) l(90%));
          }
        }
      }

      &.mobile {

        ul {
          @extend reset;
          display: inline-block;
          vertical-align: middle;

          &:before {
            color: var(--bgColor);
            content: '/';
            display: inline-block;
            padding-right: calc(var(--space) * 0.5);
            vertical-align: middle;
          }

          li {
            display: inline;

            + li {
              padding-left: calc(var(--space) * 0.5);
            }
          }

          a {
            @extend textShadow;
            color: color(var(--brandColorLighter) l(80%));
            font-size: calc(var(--fontSize) * 0.9);

            &:--link {
              color: var(--highColor);
            }
          }
        }
      }

      > li {
        display: inline-block;
        padding: calc(var(--space) * 0.5);

        > a {
          @extend textShadow;
          color: var(--textColorLighter);
          font-size: calc(var(--fontSize) * 0.9);

          &:--link {
            color: var(--highColor);

            .sub-arrow:before {
              color: inherit;
            }
          }

          .sub-arrow {
            font-size: 0;
            float: right;

            &:before {
              @mixin fontAwesomeIcon
                var(--bgColor), f0d7, 0 0 0 calc(var(--space) * 0.5);
              font-size: calc(var(--fontSize) * 0.9);
              line-height: calc(var(--space) * 1.5);
            }
          }
        }
      }
    }
  }

  .logo {
    text-align: center;
    padding: calc(var(--space) * 0.7) 0;

    @media (--screen-1024) {
      lost-column: 3/12;
      padding: calc(var(--space) * 0.7) 0 0;
      text-align: left;
    }

    &:last-child {
      @media (--screen-1024) {
        margin-top: calc(var(--space) * -2.5);
      }
    }

    &-link {
      display: block;
    }

    &-img {
      vertical-align: middle;
      width: 160px;


      @media (--screen-480) {
        width: 200px;
      }

      .home {
        @extend animate;
        opacity: 0;
      }
    }

    &:--link {
      .logo-img .home {
        opacity: 0.5;
      }
    }
  }

  .supplier-logo {
    @extend shadow;
    background: var(--bgColor);
    margin: var(--space) calc(var(--space) * -1) 0 calc(var(--space) * -1);
    padding: calc(var(--space) * 1.2);

    @media (--screen-1300) {
      margin: var(--space) 0 0 0;
    }

    h1 {
      @extend reset;
      font-size: calc(var(--fontSize) * 1.7);
      word-break: break-all;

      @media (--screen-768) {
        font-size: calc(var(--fontSize) * 2);
        lost-column: 8/12;
      }

      @media (--screen-1024) {
        lost-column: 9/12;
      }
    }

    &-link {
      color: var(--textColor);
    }

    &-img {
      float: left;
      height: 50px;
      margin-right: calc(var(--space) * 0.5);
      vertical-align: middle;
      width: 50px;
    }

    .rating {
      float: right;
      font-size: var(--fontSize);
      padding-top: var(--space);
      width: 173px;
    }
  }

  .search-form {
    clear: fix;
    display: flex;
    width: 100%;

    @media (--screen-640) {
      lost-column: 9/12;
    }

    @media (--screen-1024) {
      display: block;
      margin: calc(var(--space) * 1.2) 0 0;
    }

    form {
      display: flex;
      width: 100%;

      @media (--screen-1024) {
        display: block;
      }
    }

    &__select {
      background: var(--textColorLighter);
      border-radius: var(--radius) 0 0 var(--radius);
      border: 2px solid var(--highColor);
      border-width: 2px 0 2px 2px;
      color: var(--textColor);
      font-size: calc(var(--fontSize) * 0.9);
      height: auto;
      line-height: calc(var(--space) * 1.5);
      lost-column: 4/16 0 0;
      padding: calc(var(--space) * 0.9) var(--space);
      min-width: calc(var(--space) * 8);

      @media (--screen-1024) {
        lost-column: 3/16 0 0;
      }

      &:--link {
        border-color: var(--highColor);
      }

      ul.list {
        width: 100%;
        z-index: 2000;
      }

    }

    &__text {
      border: none;
      padding: 0;
      height: 100%;
      width: 100%;

      &-box {
        background: var(--bgColor);
        border: 2px solid var(--highColor);
        border-width: 2px 0;
        flex: 1;
        font-size: calc(var(--fontSize) * 0.9);
        line-height: 1.35em \9\0;
        padding: calc(var(--space) * 0.9) var(--space);

        @media (--screen-480) {
          max-width: 100%;
        }

        @media (--screen-1024) {
          lost-column: 11/16 0 0;
        }
      }
    }

    input[type='submit'] {
      display: none;
    }

    &__label {
      @extend textShadow;
      background: var(--highColor);
      border: 2px solid var(--highColor);
      border-width: 2px 2px 2px 0;
      border-radius: 0 var(--radius) var(--radius) 0;
      color: var(--bgColor);
      cursor: pointer;
      font-size: 0;
      lost-column: 3/16 0 0;
      min-width: calc(var(--space) * 7);
      padding: calc(var(--space) * 0.9) var(--space);
      text-align: center;
      white-space: nowrap;

      @media (--screen-640) {
        font-size: calc(var(--fontSize) * 0.9);
        line-height: calc(var(--space) * 1.48);
      }

      @media (--screen-1024) {
        min-width: auto;
        lost-column: 2/16 0 0;
      }

      i {
        font-size: var(--fontSize);
        line-height: var(--fontSize);
        padding: calc(var(--space) * 0.93) calc(var(--space) * 0.5)
                 calc(var(--space) * 0.93) calc(var(--space) * 0.9);

        @media (--screen-640) {
          padding: 0 calc(var(--space) * 0.5) 0 0;
        }
      }
    }
  }
}