.breadcrumb {
  @extend shadow;
  background: var(--bgColor);
  line-height: var(--space);
  padding: var(--space);
  margin: 0 0 var(--fixSpaceSmaller);

  &__dot a {
    pointer-events: none;
  }

  li {
    display: inline-block;
    font-size: 0.9rem;

    &:last-child a {
      color: var(--highColor);
      font-weight: bold;
      pointer-events: none;
      width: auto;
    }

    &:nth-child(n+2) {
      display: none;
    }

    &:nth-last-child(-n+2) {
      display: inline-block;
    }

    @media (--screen-1024) {
      &:nth-last-child(-n+4) {
        display: inline-block;
      }
    }

    @media (--screen-1300) {
      &:nth-last-child(-n+6) {
        display: inline-block;
      }
    }

    + li:before {
      @mixin fontAwesomeIcon
        var(--textColor), f105, 0 var(--space) 0 calc(var(--space) * 0.5);
      font-weight: normal;
      vertical-align: top;
    }
  }

  a {
    @extend animate;
    color: var(--textColor);
    display: inline-block;
    max-width: calc(var(--space) * 9);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (--screen-1300) {
      max-width: calc(var(--space) * 13);
    }

    &:--link {
      color: var(--brandColorLighter);
    }

    i {
      margin-right: calc(var(--space) * 0.5);
    }
  }
}