.preloader {
  background: var(--bgColor);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3001;

  &__img {
    left: 50%;
    height: 60px;
    margin: -30px 0 0 -100px;
    position: absolute;
    top: 50%;
    width: 200px;

    .l {
      &_1,
      &_2 {
        transform-origin: bottom;
      }
      &_1 {
        animation: preloader-loading 1s infinite alternate;
      }

      &_2 {
        animation: preloader-loading-2 1s infinite alternate;
      }
    }
  }
}