.sm-custom {
  background: var(--bgColor);

  li + li {
    border-top: 1px solid color(var(--textColor) a(10%));
  }

  ul {
    background: color(var(--textColor) l(95%));
    border-left: 3px solid var(--highColor);

    ul {
      border-left: none;
      background: color(var(--textColor) l(90%));

      ul {
        background: color(var(--textColor) l(85%));
      }
    }
  }

  a {
    @extend animate;
    padding: calc(var(--space) * 0.85);
    /* make room for the toggle button (sub indicator) */
    padding-right: 58px;
    color: #555555;
    font-family: var(--fontFamily);
    font-size: 16px;
    font-weight: normal;
    line-height: 17px;
    text-decoration: none;

    &:hover, &:focus, &:active {
      /* make room for the toggle button (sub indicator) */
      background: color(var(--textColor) a(8%));
      color: var(--brandColorLighter);
      font-family: var(--fontFamily);
    }

    &.current {
      color: var(--brandColorLighter);
    }

    &.disabled {
      color: #bbbbbb;
    }

    span {
      font-size: calc(var(--space) * 0.8);
      margin-left: calc(var(--space) * 0.5);
    }

    span.sub-arrow {
      font-size: 0;
      position: absolute;
      top: 50%;
      margin-top: -17px;
      margin-left: 0;
      left: auto;
      right: 4px;
      overflow: hidden;
      text-align: center;
      text-shadow: none;
      background: rgba(255, 255, 255, 0.5);

      &:before {
        @extend animate;
        @mixin fontAwesomeIcon
          var(--highColor), f078, var(--space);
        font-size: var(--fontSize);
      }
    }

    &.highlighted span.sub-arrow:before {
      transform: rotate(180deg);
    }
  }

  li {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  > li:first-child {
    border-top: 0;
  }
}

@media (--screen-1024) {
  /* Switch to desktop layout
  -----------------------------------------------
     These transform the menu tree from
     collapsible to desktop (navbar + dropdowns)
  -----------------------------------------------*/
  /* start... (it's not recommended editing these rules) */
  .sm-custom {
    ul {
      background: var(--bgColor);
      border-left: 3px solid var(--highColor);
      box-shadow: var(--shadow);
      position: absolute;
      width: 14em;

      ul {
        border-left: none;
        background: var(--bgColor);

        ul {
          background: var(--bgColor);
        }
      }
    }
    li {
      float: left;
    }
    &.sm-rtl li {
      float: right;
    }
    ul li, &.sm-rtl ul li, &.sm-vertical li {
      float: none;
    }
    a {
      white-space: nowrap;
    }
    ul a, &.sm-vertical a {
      white-space: normal;
    }
    .sm-nowrap > li > {
      a, :not(ul) a {
        white-space: nowrap;
      }
    }
    a {
      &.has-submenu {
        padding-right: 58px;
      }
    }
    li {
      border-top: 0;
    }
    > li > ul {
      &:before {
        content: '';
        position: absolute;
        top: -18px;
        left: 30px;
        width: 0;
        height: 0;
        overflow: hidden;
        border-width: 9px;
        border-style: dashed dashed solid dashed;
        border-color: transparent transparent #bbbbbb transparent;
      }
      &:after {
        content: '';
        position: absolute;
        top: -18px;
        left: 30px;
        width: 0;
        height: 0;
        overflow: hidden;
        border-width: 9px;
        border-style: dashed dashed solid dashed;
        border-color: transparent transparent #bbbbbb transparent;
        top: -16px;
        left: 31px;
        border-width: 8px;
        border-color: transparent transparent white transparent;
      }
    }
    ul {
      a {
        border: 0 !important;

        &.has-submenu {
          padding-right: 58px;
        }

        span.sub-arrow {
          right: 8px;
          top: 50%;
        }
      }
    }
    span {
      &.scroll-up, &.scroll-down {
        position: absolute;
        display: none;
        visibility: hidden;
        overflow: hidden;
        background: white;
        height: 20px;
      }
      &.scroll-up:hover, &.scroll-down:hover {
        background: #eeeeee;
      }
      &.scroll-up:hover span {
        &.scroll-up-arrow, &.scroll-down-arrow {
          border-color: transparent transparent var(--brandColorLighter) transparent;
        }
      }
      &.scroll-down:hover span.scroll-down-arrow {
        border-color: var(--brandColorLighter) transparent transparent transparent;
      }
      &.scroll-up-arrow {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -6px;
        width: 0;
        height: 0;
        overflow: hidden;
        border-width: 6px;
        border-style: dashed dashed solid dashed;
        border-color: transparent transparent #555555 transparent;
      }
      &.scroll-down-arrow {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -6px;
        width: 0;
        height: 0;
        overflow: hidden;
        border-width: 6px;
        border-style: dashed dashed solid dashed;
        border-color: transparent transparent #555555 transparent;
        top: 8px;
        border-style: solid dashed dashed dashed;
        border-color: #555555 transparent transparent transparent;
      }
    }
    &.sm-vertical {
      a {
        span.sub-arrow {
          right: calc(var(--space) * 0.5);
          top: 50%;

          &:before {
            @mixin fontAwesomeIcon
              var(--highColor), f054, var(--space);
            font-size: var(--fontSize);
          }
        }
      }
      > li > ul {
        &:before, &:after {
          display: none;
        }
      }
    }
  }
  /* ...end */
}