.table-box {
  clear: both;
  overflow-x: auto;
  overflow-y: hidden;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    min-width: 50%;
    max-width: 100%;

    thead {
      background: color(var(--brandColorLighter) l(90%));
      color: var(--brandColorLighter);
      font-weight: bold;
    }

    tr:nth-child(even) {
      background: var(--textColorLighter);
    }

    th,
    td {
      border: 1px solid color(var(--textColor) l(85%));
      padding: calc(var(--space) * 0.5);
    }
  }
}